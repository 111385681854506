import styled from "styled-components";

export const StyledPdfViewerContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: #f8f8f8;
    width: 100%;
    max-width: 100%;
    position: relative;
`;

export const StyledPdfViewerPagesContainer = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 100%;
    width: 100%;
    position: relative;
`;

export const StyledPdfViewerMain = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    position: relative;
    overflow-x: scroll;
    padding-right: 55px;
`;

export const StyledToolbarContainer = styled.div`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 20;
`;

export const StyledNotesContainer = styled.div`
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 30%;
    min-width: 350px;
    border-left: 1px solid #e0e0e0;
    padding: 16px;

`;
