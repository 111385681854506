import React, { PropsWithChildren, useRef, useState } from "react";
import {
    FullscreenOutlined,
    FullscreenExitOutlined,
    CloseOutlined,
} from "@mui/icons-material";
import {
    SidebarContainer,
    SidebarContent,
    SidebarHeader,
    SidebarResizeHandle,
    SidebarTitle,
    SidebarToolbar,
    SidebarWrapper,
} from "./Sidebar.styled";
import { IconButton } from "@mui/material";

export type SidebarProps = {
    open: boolean;
    onClose: () => void;
    content: React.ReactNode;
    title: string;
};

export const Sidebar: React.FC<SidebarProps> = ({
    open,
    onClose,
    content,
    title,
}) => {
    const TOOLBAR_WIDTH = 60;
    const MIN_WIDTH = 600;
    const INITIAL_WIDTH = MIN_WIDTH;
    const MAX_WIDTH = 1000;
    const [expanded, setExpanded] = React.useState(false);

    const [width, setWidth] = useState(INITIAL_WIDTH); // Initial width of the sidebar
    const sidebarRef = useRef<HTMLDivElement>(null);

    const handleMouseDown: React.MouseEventHandler<HTMLDivElement> = (e) => {
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
    };

    const handleMouseMove = (e) => {
        if (sidebarRef.current) {
            const newWidth =
                sidebarRef.current.getBoundingClientRect().right - e.clientX;
            if (newWidth >= MIN_WIDTH && newWidth <= MAX_WIDTH) {
                setWidth(newWidth);
            }
        }
    };

    const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
    };

    return (
        <SidebarContainer
            width={width}
            toolbarWidth={TOOLBAR_WIDTH}
            open={open}
        >
            <SidebarResizeHandle onMouseDown={handleMouseDown} />
            <SidebarWrapper
                width={width}
                ref={sidebarRef}
                style={{ width: `${width}px` }}
            >
                <SidebarHeader>
                    <SidebarTitle>{title}</SidebarTitle>
                    <IconButton onClick={onClose}>
                        <CloseOutlined />
                    </IconButton>
                </SidebarHeader>
                <SidebarContent>{content}</SidebarContent>
            </SidebarWrapper>
        </SidebarContainer>
    );
};
