import React, { FC, useEffect, useRef } from "react";
import "./Municipal.scss";
import ContainerWithResizableSideBar from "src/components/ContainerWithResizableSideBar/ContainerWithResizableSideBar";
import ReturnTreeViewer from "src/components/ReturnTreeViewer/ReturnTreeViewer";
import MultiTabContainer from "src/components/MultiTabContainer/MultiTabContainer";
import { useAppDispatch, useAppSelector } from "src/hooks";
import CompanyOptions from "src/components/CompanyOptions/CompanyOptions";
import { WebSocketService } from "src/services";
import { FixMeLater, WEBSOCKET_EVENT_TYPES, WebSocketEvent } from "src/types";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";

const Municipal: FC = () => {
    const webSocketService = WebSocketService.getInstance();

    const wsRef = useRef<WebSocket | null>(null);

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );

    const companyOptionsOpen: boolean | undefined = useAppSelector(
        (state) => state?.Municipal?.value.companyOptionsOpen,
    );

    const dispatch = useAppDispatch();

    const getDocument = () => {
        return <MultiTabContainer />;
    };

    const getContent = () => (
        <div className="municipal-document-container">
            <div className="municipal-document">{getDocument()}</div>
        </div>
    );

    const handleMessage = ({ eventType, payload, sessionId }: WebSocketEvent) => {
        switch (eventType) {
            case WEBSOCKET_EVENT_TYPES.TASK_PROGRESS:
                dispatch(
                    GlobalStateActions[product?.productName].setImportProgress(
                        {
                            key: sessionId,
                            progress: payload?.progress
                        }
                    )
                );
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        const fetchNotifications = async () => {
            if (!wsRef.current || wsRef.current.readyState === WebSocket.CLOSED) {
                try {
                    const socket = await webSocketService.openWebSocketConnection(product.productName?.toLowerCase());

                    socket.onopen = () => {
                        socket.send("opened Municipal WebSocket");

                        const keepAliveIntervalId = setInterval(
                            () => {
                                if (socket && wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                                    socket.send("keepalive");
                                } else {
                                    clearInterval(keepAliveIntervalId);
                                }
                            },
                            // Set the interval to 30 seconds to prevent from becoming inactive.
                            30 * 1000 
                        );
                    };

                    socket.onmessage = (event: MessageEvent) => {
                        handleMessage(JSON.parse(event?.data));
                    };

                    socket.onerror = (event) => {
                        console.error("Error fetching Municipal notifications", event);
                    };

                    socket.onclose = (event: CloseEvent) => {
                        if (!event.wasClean) {
                            console.error(
                                "Municipal WebSocket is closed. Reconnect will be attempted in 10 seconds.",
                                event
                            );
                            setTimeout(() => {
                                fetchNotifications();
                            }, 10000);
                        }
                    };

                    wsRef.current = socket;

                } catch (error) {
                    console.error("Error fetching Municipal notifications", error);
                }
            }
        };

        fetchNotifications();

        return () => {
            if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                wsRef.current.close();
            }
        };
    }, []);

    return (
        <div className="municipal-container">
            {companyOptionsOpen ? (
                <CompanyOptions />
            ) : (
                <ContainerWithResizableSideBar
                    sideBarContent={<ReturnTreeViewer />}
                    content={getContent()}
                />
            )}
        </div>
    );
};

export default Municipal;
